import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { getOrder } from '../../../requests';
import { currency } from '../../../constants';

import { add as addMessage } from '../../../state/notifySlice';

import Fetching from '../../elements/Fetching/Fetching';
import Container from '../../elements/Container/Container';

function OrderInfo() {
  const { id, key } = useParams();
  const orderId = id ? Number(id) : null;
  const orderKey = key ? key : null;

  const [fetching, setFetching] = useState(false);
  const [order, setOrder] = useState({});

  const dispatch = useDispatch();

  const updateOrder = (id, key, check) => {
    if (!check.disposed) setFetching(true);
    getOrder({ id, key })
      .then(({ data }) => {
        if (!check.disposed) setOrder(data || {});
      })
      .catch(error => {
        console.error(error);
        if (!check.disposed) dispatch(addMessage({
          type: 'error',
          text: 'Erro ao carregar "order"'
        }));
      })
      .finally(() => {
        if (!check.disposed) setFetching(false);
      });
  };

  useEffect(() => {
    const check = { disposed: false };
    if (orderId) { updateOrder(orderId, orderKey, check); }
    return () => check.disposed = true;
  }, [orderId, orderKey]);

  const products = order['Content'] ? JSON.parse(order['Content']) : [];
  const meta = order['Meta'] || {};
  const address = meta['Address'] ? JSON.parse(meta['Address']) : {};
  const promos = meta['Promos'] ? JSON.parse(meta['Promos']) : [];
  const delivery = meta['Delivery'] ? !!Number(meta['Delivery']) : false;

  const deliveryCost = delivery ? (
    meta['DeliveryCost'] ? Number(meta['DeliveryCost']) || 0 : 0
  ) : 0;

  const superTotal = Number(meta['Total']) + deliveryCost;

  return (
    <div className="Order space-two">
      <Container>
        {fetching ? <Fetching /> : (
          <div>
            <div className="OrderSection-title">Pedido {order['Title'] || orderId}</div>
            <p><span className="OrderProp-title">Status:</span> <span className="badge">{meta['Status']}</span></p>
            <p><span className="OrderProp-title">Criação:</span> {order['Created']}</p>
            <p><span className="OrderProp-title">Atualizada:</span> {order['Updated'] || '(nunca)'}</p>
            <div className="flex wrap between">
              <div>
                <div className="OrderSection-title">Detalhes</div>
                <p><span className="OrderProp-title">Nome:</span> {meta['Name']}</p>
                <p><span className="OrderProp-title">Telefone:</span> {meta['Phone']}</p>
                <p><span className="OrderProp-title">E-mail:</span> {meta['Email'] || '(vazio)'}</p>
                <p><span className="OrderProp-title">Número contribuinte:</span> {meta['Tax'] || '(vazio)'}</p>
                <p><span className="OrderProp-title">Comente:</span> {meta['Comment'] || '(vazio)'}</p>
                <p><span className="OrderProp-title">Hora {delivery ? 'receber' : 'levantar'}:</span> {meta['ReceiveTime']}</p>
              </div>
              <div>
                <div className="OrderSection-title">Entrega</div>
                <p><span className="OrderProp-title">Entrega:</span> {delivery ? 'Sim' : 'Não'}{deliveryCost && ` (${deliveryCost} ${currency})`}</p>
                <p><span className="OrderProp-title">Endereço:</span> {address.street}, {address.building}, {address.apartment}, {address.entrance}, {address.floor}</p>
                <p><span className="OrderProp-title">Pagamento:</span> {meta['Payment']}</p>
              </div>
              <div>
                <div className="OrderSection-title">Ofertas</div>
                {promos.length > 0 ? promos.map((item, index) => (
                  <div key={index} className="OrderProduct flex between">
                    <div className="ungrow">
                      <div className="OrderProduct-title">{item.product.Title}</div>
                      <div className="OrderProduct-variants">
                        {Object.keys(item.variants).map(prop => (<span key={prop}>{prop}: {item.variants[prop]}</span>))}
                      </div>
                    </div>
                  </div>
                )) : '(nenhum)'}
              </div>
              <div>
                <div className="OrderSection-title">Produtos</div>
                {products.map((item, index) => (
                  <div key={index} className="OrderProduct flex between">
                    <div className="ungrow">
                      <div className="OrderProduct-title">{item.product.Title}</div>
                      <div className="OrderProduct-variants">
                        {Object.keys(item.variants).map(prop => (<span key={prop}>{prop}: {item.variants[prop]}</span>))}
                      </div>
                      <div className="OrderProduct-options">
                        {item.options.map(o => (<span key={o.id}>{o.title} &times; {o.quantity}</span>))}
                      </div>
                    </div>
                    <div className="unshrink">
                      <span className="OrderProduct-price">{item.price} {currency}</span> &times; {item.quantity}
                    </div>
                  </div>
                ))}
                <div className="OrderTotal-text"><span className="OrderProp-title">Total:</span> {superTotal} {currency}</div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default OrderInfo;
