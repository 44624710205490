import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { currency, IS_DEV } from '../../../constants';
import { makeOrder } from '../../../requests';

import { add as addMessage } from '../../../state/notifySlice';
import { clear, save } from '../../../state/cartSlice';
import {
  setPromoAvailable,
  setSelectedProducts,
  // setUsedCartItems,
  setPromoProducts,
  clearSelectedProducts,
} from '../../../state/promosSlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Modal from '../../elements/Modal/Modal';
import Image from '../../elements/Image/Image';

import './Order.css';

const defaults = {
  delivery: true,
  payment: 'Cash'
}

const MIN_PHONE_LENGTH = 3;

const checkPromos = (promos = [], cart = []) => {
  const available = [];

  promos.forEach(promo => {
    let items = [...cart];

    if (IS_DEV) console.log(promo['Title'], promo['Conditions']);

    const { Weekdays: wd, Quantity: q, Price: p, ProductID: pid, ProductVariant: pv } = promo['Conditions'];

    const pass = {
      entries: 0,
      price: true,
      products: true,
      quantity: true,
      variants: true,
      weekdays: true
    };

    // (ok) check weekdays
    if (wd && wd.length > 0) {
      const today = new Date().getDay();
      pass.weekdays = wd.includes(today);
      if (IS_DEV) console.log(`- weekdays${pass.weekdays?'':' not'} passed`);
    }
    
    // (ok) filter products
    if (pid && pid.length > 0) {
      items = items.filter(item => {
        return pid.includes(item.product.ID);
      });
      if (IS_DEV) console.log('- filtered by id', items);
      pass.products = items.length > 0;
    }

    // (ok) filter product variants
    if (pv && pv.length > 0) {
      items = items.filter(item => {
        let same = true;
        pv.forEach(v => Object.keys(v).forEach(k => {
          if (item.variants[k] !== v[k]) same = false;
        }));
        return same;
      });
      if (IS_DEV) console.log('- filtered by variants', items);
      pass.variants = items.length > 0;
    }

    // (ok) check quantity
    if (q && q > 0) {
      let count = 0;
      items.forEach(item => {
        count += item.quantity;
      });
      pass.quantity = count >= q;
      if (IS_DEV) console.log(`- quantity${pass.quantity?'':' not'} passed`);
      pass.entries = Math.floor(count / q);
      if (IS_DEV) console.log('- entries', pass.entries);
    }

    // (~) check price
    if (p && p > 0) {
      let total = 0;
      items.forEach(item => {
        total += item.price * Math.min(q, item.quantity); // TODO: check
      });
      pass.price = total >= p;
      if (IS_DEV) console.log(`- price${pass.price?'':' not'} passed`);
    }

    // (~) count entries
    const cond = pass.price && pass.products && pass.quantity && pass.variants && pass.weekdays;
    pass.entries = cond ? pass.entries : 0;

    if (IS_DEV) console.log('- pass results', pass);

    available.push({ id: promo['ID'], count: pass.entries });

    if (IS_DEV) console.log('-----');
  });

  return available;
}

const straight = 'Quando pronto';

function OrderForm() {
  const { isWorking } = useSelector(state => state.workingTime);
  const { items: products } = useSelector(state => state.products);
  const { items, quantity, total } = useSelector(state => state.cart);
  const { promos, available, selectedProducts: sp, selectIndex, selectPromoId, selectProducts, selectVariant } = useSelector(state => state.promos);
  const { settings } = useSelector(state => state.settings);

  let dc = { enabled: false, cost: 0, minCartTotal: 0 };

  const deliveryConfigRaw = settings['Delivery Config'] || '';
  try { dc = deliveryConfigRaw ? JSON.parse(deliveryConfigRaw) || dc : dc; }
  catch (e) { console.error(e); }

  // if (IS_DEV) console.log(sp, Object.keys(sp).map(id => Object.keys(sp[id]).map(i => sp[id][i]).flat()).flat());
  
  const order = localStorage.getItem('order');
  const saved = order ? JSON.parse(order) : {};

  const [fetching, setFetching] = useState(false);

  const [name, setName] = useState(saved.name || '');
  const [phone, setPhone] = useState(saved.phone || '');
  const [email, setEmail] = useState(saved.email || '');
  const [tax, setTax] = useState(saved.tax || '');

  const [delivery, setDelivery] = useState(dc.enabled && defaults.delivery);
  const [street, setStreet] = useState(saved.street || '');
  const [building, setBuilding] = useState(saved.building || '');
  const [apartment, setApartment] = useState(saved.apartment || '');
  const [entrance, setEntrance] = useState(saved.entrance || '');
  const [floor, setFloor] = useState(saved.floor || '');
  const [payment, setPayment] = useState(saved.payment || defaults.payment);

  const [comment, setComment] = useState('');
  const [time, setTime] = useState(straight);

  const [errors, setErrors] = useState([]);

  const history = useHistory();

  const dispatch = useDispatch();
  
  const filteredPromos = promos ? promos.filter(promo => available[promo['ID']] > 0) : [];

  const superTotal = total + (delivery ? dc.cost : 0);
  
  const placeOrder = () => {
    setFetching(true);
    const data = {
      contact: { name, phone, email },
      comment,
      time,
      delivery,
      deliveryCost: dc.cost,
      payment,
      tax,
      address: { street, building, apartment, entrance, floor },
      products: items.map(item => ({
        product: {
          ID: item.product.ID,
          Title: item.product.Title
        },
        variants: item.variants,
        options: item.options.map(o => ({
          id: o.option.ID,
          title: o.option.Title,
          quantity: o.quantity
        })),
        quantity: item.quantity,
        price: item.price
      })),
      promos: Object.keys(sp).map(id => Object.keys(sp[id]).map(i => sp[id][i]).flat()).flat().map(item => ({
        product: {
          ID: item.product.ID,
          Title: item.product.Title
        },
        variants: item.variants
      })),
      quantity,
      total
    };
    if (IS_DEV) console.log('Order', data);
    makeOrder(data)
      .then(({ data }) => {
        const { id, key } = data;
        if (!!id && !!key) {
          dispatch(clear());
          dispatch(save());
          dispatch(clearSelectedProducts());
          dispatch(addMessage({type: 'info', text: 'Pedido criado com sucesso' }));
          history.push(`/order/${id}/${key}`);
        } else {
          dispatch(addMessage({type: 'warning', text: 'Erro ao criar pedido' }));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(addMessage({type: 'error', text: 'Erro ao criar pedido' }));
      })
      .finally(() => { setFetching(false); });
  };

  const validate = () => {
    const errors = [];
    if (!isWorking) errors.push('Não estou recebendo pedidos agora');
    if (!name) errors.push('Por favor, insira seu nome');
    if (!phone || phone.length < MIN_PHONE_LENGTH)
      errors.push('O número de telefone é obrigatório');
    if (delivery && total < dc.minCartTotal)
      errors.push('Valor do pedido abaixo do mínimo');
    if (delivery && (!street || !building || !apartment))
      errors.push('Por favor, preencha os campos de endereço');
    setErrors(errors);
    return errors.length === 0;
  };

  const saveFields = (replace = {}) => {
    const data = { name, phone, email, street, building, apartment, entrance, floor, tax, ...replace };
    localStorage.setItem('order', JSON.stringify(data));
  };

  useEffect(() => {
    const update = checkPromos(promos, items);
    update.forEach(u => {
      dispatch(setPromoAvailable(u));
    });
  }, [promos, items]);

  useEffect(() => {
    validate();
  }, [isWorking, name, phone, delivery, total, settings, street, building, apartment]);

  const promoProducts = (conditions) => {
    const pids = conditions['PromoProductID'] || [];
    const variant = conditions['PromoProductVariant'] || [];
    const promoVariant = {};

    if (IS_DEV) console.log({ pids, variant });

    const filtered = products.filter(p => {

      const pid = p['ID'];
      const idIsOk = pids.find(i => i === pid) !== undefined;

      let varIsOk = false;
      const vars = p['Meta']['Variants'] ? JSON.parse(p['Meta']['Variants']) || {} : {};


      variant.forEach(v => Object.keys(v).forEach(k => {
        if (vars[k] && Object.keys(vars[k]).includes(v[k])) {
          promoVariant[pid] = { [k]: v[k] };
          varIsOk = true;
        }
      }));

      if (IS_DEV) console.log({ product: p, promoVariant, idIsOk, varIsOk, vars });

      return idIsOk && varIsOk;
    });

    if (IS_DEV) console.log({ promoVariant });

    return [filtered, promoVariant];
  };

  const onOrderClick = () => {
    if (validate()) placeOrder();
    else dispatch(addMessage({
      type: 'warning',
      text: 'Condições do pedido não atendidas'
    }));
  };

  return (
    <div className="OrderForm">
      <h2>Pedido</h2>
      <div className="flex wrap between">
        <div>
          <div className="space-one">
            <h4>Seus contatos</h4>
            <div className="space-third">
              <input
                className="input-field input-field-wide"
                type="text"
                value={name}
                onChange={(e) => { setName(e.target.value); saveFields({ name: e.target.value }); }}
                placeholder="Seu nome"
                required
              />
            </div>
            <div className="space-third">
              <input
                className="input-field input-field-wide"
                type="text"
                value={phone}
                onChange={(e) => { setPhone(e.target.value); saveFields({ phone: e.target.value }); }}
                placeholder="Número de telefone"
                required
              />
            </div>
            <div className="space-third">
              <input
                className="input-field input-field-wide"
                type="email"
                value={email}
                onChange={(e) => { setEmail(e.target.value); saveFields({ email: e.target.value }); }}
                placeholder="E-mail (opcional)"
              />
            </div>
            <div className="space-third">
              <input
                className="input-field input-field-wide"
                type="text"
                value={tax}
                onChange={(e) => { setTax(e.target.value); saveFields({ tax: e.target.value }); }}
                placeholder="Número contribuinte (opcional)"
              />
            </div>
          </div>
          <div className="space-one">
            <div>
              <h4>Entrega</h4>
              <div className="flex space-third">
                <div>
                  <input className="input-radio" id="orderDeliveryNo" type="radio" defaultChecked={!delivery} onClick={() => setDelivery(false)} />
                  <label className="input-label" htmlFor="orderDeliveryNo">Levantar</label>
                </div>
                {dc.enabled && (
                  <div>
                    <input className="input-radio" id="orderDeliveryYes" type="radio" defaultChecked={delivery} onClick={() => setDelivery(true)} />
                    <label className="input-label" htmlFor="orderDeliveryYes">Entrega</label>
                  </div>
                )}
              </div>
              {!dc.enabled && <div>Só apenas para Teikaway</div>}
            </div>
            {delivery && (
              <div>
                {dc.minCartTotal > 0 && <div> Carrinho mínimo: {dc.minCartTotal} {currency} </div>}
                <h6>Endereço de entrega</h6>
                <div className="flex space-third input-group">
                  <input
                    className="input-field"
                    type="text"
                    value={street}
                    onChange={(e) => { setStreet(e.target.value); saveFields({ street: e.target.value }); }}
                    placeholder="Rua"
                    required
                  />
                  <input
                    className="input-field input-field-narrow"
                    type="text"
                    value={building}
                    onChange={(e) => { setBuilding(e.target.value); saveFields({ building: e.target.value }); }}
                    placeholder="Número do porta"
                    required
                  />
                </div>
                <div className="flex space-third input-group">
                  <input
                    className="input-field input-field-narrow "
                    type="text"
                    value={entrance}
                    onChange={(e) => { setEntrance(e.target.value); saveFields({ entrance: e.target.value }); }}
                    placeholder="Entrada"
                  />
                  <input
                    className="input-field input-field-narrow"
                    type="text"
                    value={floor}
                    onChange={(e) => { setFloor(e.target.value); saveFields({ floor: e.target.value }); }}
                    placeholder="Andar"
                  />
                  <input
                    className="input-field input-field-narrow"
                    type="text"
                    value={apartment}
                    onChange={(e) => { setApartment(e.target.value); saveFields({ apartment: e.target.value }); }}
                    placeholder="Apartamento"
                    required
                  />
                </div>
                <h6>Forma de pagamento</h6>
                <div className="flex space-third">
                  <div>
                    <input
                      className="input-radio"
                      id="orderPaymentCash"
                      type="radio"
                      defaultChecked={payment==='Cash'}
                      onClick={() => setPayment('Cash')}
                    />
                    <label className="input-label" htmlFor="orderPaymentCash">Dinheiro</label>
                  </div>
                  <div>
                    <input
                      className="input-radio"
                      id="orderPaymentCard"
                      type="radio"
                      defaultChecked={payment==='Card'}
                      onClick={() => setPayment('Card')}
                    />
                    <label className="input-label" htmlFor="orderPaymentCard">Cartão</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="shrink">
          <div className="space-one">
            <h4>Posições de pedidos</h4>
            <div className="space-third">
              {items.map((item) => (
                <div key={item.id} className="OrderProduct flex between">
                  <div className="ungrow">
                    <div className="OrderProduct-title">{item.product.Title}</div>
                    <div className="OrderProduct-variants">
                      {Object.keys(item.variants).map(prop => (<span key={prop}>{prop}: {item.variants[prop]}</span>))}
                    </div>
                    <div className="OrderProduct-options">
                      {item.options.map(o => (<span key={o.option.ID}>{o.option.Title} &times; {o.quantity}</span>))}
                    </div>
                  </div>
                  <div className="unshrink">
                    <span className="OrderProduct-price">{Math.round(item.price * 100) / 100} {currency}</span> &times; {item.quantity}
                  </div>
                </div>
              ))}
            </div>
            <div className="space-third">
              <div>Produtos: <span>{quantity}</span></div>
              {delivery && (
                <div>Entrega: <span>{dc.cost} {currency}</span></div>
              )}
              <div>Preço total: <span className="OrderTotal">{Math.round(superTotal * 100) / 100} {currency}</span></div>
            </div>
          </div>
          <div className="space-one">
            <h4>Ofertas disponíveis</h4>
            <div className="space-third">
              {filteredPromos.length > 0 ? filteredPromos.map(promo => (
                <div key={promo['ID']}>
                  <div>{promo['Title']}</div>
                  <div className="PromoProductSelect-list">
                    {[...Array(available[promo['ID']])].map((_v, index) => (
                      <button
                        key={index}
                        type="button"
                        className="PromoProductSelect-button"
                        onClick={() => {
                          const [filtered, variant] = promoProducts(promo['Conditions']);
                          dispatch(setPromoProducts({ index, products: filtered, promoId: promo['ID'], variant }));
                        }}
                      >
                        {sp[promo['ID']] && sp[promo['ID']][index] ? sp[promo['ID']][index].product['Title'] : '+'}
                      </button>
                    ))}
                  </div>
                </div>
              )) : 'Nenhuma oferta está disponível para o seu pedido'}
            </div>
          </div>
        </div>
        <div>
          <div className="space-one">
            <h4>Comente</h4>
            <textarea
              className="input-field input-field-wide"
              onChange={(e) => setComment(e.target.value)}
              placeholder="(opcional)"
              value={comment}
            />
          </div>
          <div className="space-one">
            <h4>Hora {delivery ? 'receber' : 'levantar'}</h4>
            <div className="flex space-third">
              <div>
                <input className="input-radio" id="orderTimeStraight" type="radio" defaultChecked={time===straight} onClick={() => setTime(straight)} />
                <label className="input-label" htmlFor="orderTimeStraight">Quando pronto</label>
              </div>
              <div>
                <input className="input-radio" id="orderTimeCustom" type="radio" defaultChecked={time!==straight} onClick={() => setTime('')} />
                <label className="input-label" htmlFor="orderTimeCustom">Escolha o tempo</label>
              </div>
            </div>
            {time!==straight && (
              <div>
                <input
                  className="input-field input-field-wide"
                  type="text"
                  value={time}
                  onChange={(e) => { setTime(e.target.value); }}
                  placeholder="Tempo"
                  required
                />
                <p><small>O tempo de cozimento pode variar</small></p>
              </div>
            )}
          </div>
          {errors.length > 0 && (
            <div className="OrderErrors">
              {errors.map(error => <div className="OrderError" key={error}>{error}</div>)}
            </div>
          )}
          {fetching ? <Fetching /> : (
            <div className="space-one">
              <button disabled={!isWorking} type="button" className="button Cart-OrderButton" onClick={onOrderClick}>
                Pedido
              </button>
            </div>
          )}
        </div>
      </div>
      {selectProducts.length > 0 && (
        <Modal title="Selecione o produto promocional" onClose={() => dispatch(setPromoProducts({}))}>
          <div className="PromoProduct-list">
            {selectProducts.map((item) => (
              <button
                key={item['ID']}
                type="button"
                className="PromoProduct"
                onClick={() => {
                  dispatch(setSelectedProducts({
                    promoId: selectPromoId, index: selectIndex, product: item, variants: selectVariant[item['ID']]
                  }));
                  dispatch(setPromoProducts({}));
                }}
              >
                <div><Image className="PromoProduct-image" src={item.Image} /></div>
                <div className="PromoProduct-title">{item['Title']}</div>
                <div className="PromoProduct-variant">
                  {Object.keys(selectVariant[item['ID']]).map(k => `${k}: ${selectVariant[item['ID']][k]}`)}
                </div>
              </button>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
}

function OrderPage() {  
  return (
    <div className="Order space-two">
      <Container>
        <OrderForm />
      </Container>
    </div>
  );
}

export default OrderPage;
