import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { currency } from '../../../constants';

import { add as addMessage } from '../../../state/notifySlice';
import { addItem, save } from '../../../state/cartSlice';

import {
  setCurrentProduct as setCurrentProductForOptions,
  setSelectedOptions
} from '../../../state/optionsSlice';

import {
  setCurrent as setCurrentProductForVariants,
  setVariants
} from '../../../state/variantsSlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Image from '../../elements/Image/Image';

import './Products.css';

const VARIANTS_SELECTOR_FROM = 4;

const Product = ({ item, index }) => {
  const [desc, setDesc] = useState(false);
  const [quan, setQuan] = useState(1);

  const id = item ? item.ID : 0;

  const { variants } = useSelector(state => (state.variants));
  const { selected: options } = useSelector(state => (state.options));

  const selectedVariants = variants[id] || [];
  const selectedOptions = options[id] || [];

  const availableVariants = item.Meta && item.Meta['Variants']
    ? JSON.parse(item.Meta['Variants']) || {} : {};
  const availableOptions = item.Meta && item.Meta['Options']
    ? JSON.parse(item.Meta['Options']) || [] : [];

  const showOptions = availableOptions.length > 0;

  const dispatch = useDispatch();

  const setVari = (selected) => {
    dispatch(setVariants({ productId: id, selected }));
  };

  const defaultVariants = () => {
    const vari = {};
    Object.keys(availableVariants).forEach(v => {
      const names = Object.keys(availableVariants[v]);
      if (names) vari[v] = names[0];
    });
    setVari(vari);
  };

  useEffect(() => {
    defaultVariants();
  }, []);

  const toggleDesc = () => setDesc(v => !v);

  const plus = () => {
    setQuan(quan + 1);
  };

  const minus = () => {
    if (quan > 1) setQuan(quan - 1);
  };

  const price = () => {
    let price = Number(item.Meta['Price']) || 0;
    let variantsPrice = 0;
    Object.keys(selectedVariants).forEach(v => {
      if (availableVariants[v]) variantsPrice += Number(availableVariants[v][selectedVariants[v]]) || 0;
    });
    let optionsPrice = 0;
    selectedOptions.forEach(s => {
      let optionPrice = Number(s.option.Meta['Price']) || 0;
      const optionPrices = s.option.Meta['Variants']
        ? JSON.parse(s.option.Meta['Variants']) || {} : {};
      Object.keys(optionPrices).forEach(v => {
        optionPrice = Number(optionPrices[v][selectedVariants[v]]) || 0;
      });
      optionsPrice += optionPrice * s.quantity;
    });
    price = Math.max(price, variantsPrice) + optionsPrice;
    return Math.round(price * 100) / 100;
  }

  const cartItem = () => ({
    product: item,
    quantity: quan,
    variants: selectedVariants,
    options: selectedOptions,
    price: price(),
  });

  const toCart = () => {
    dispatch(addItem(cartItem()));
    dispatch(save());
    dispatch(setSelectedOptions({ productId: id, selected: [] }));
    setQuan(1);
    dispatch(addMessage({type: 'info', text: 'Produto adicionado ao carrinho' })); // TODO: translate
  };

  return (
    <div className="Product appear-seq" style={{ '--animation-order': index }}>
      <div className="ProductTop">
        <div className="ProductImage">
          <Image src={item.Image} loading={index<4?'eager':'lazy'} />
        </div>
        <div className={`ProductDescription${!desc ? '' : ' shown'}`}>{item.Content}</div>
        <button
          hidden={!item.Content}
          type="button"
          className={`ProductDescriptionToggle${!desc ? '' : ' active'}`}
          onClick={toggleDesc}
          title="Show description"
        />
      </div>
      <div className="ProductBottom">
        <div className="ProductTitle">{item.Title}</div>
        <div className="ProductVariants">
          {Object.keys(availableVariants).map(prop => {
            const keys = Object.keys(availableVariants[prop]);
            return (
              <div className="ProductVariant" key={prop}>
                <span className="ProductVariantsTitle">{prop}: </span>
                {keys.length > VARIANTS_SELECTOR_FROM ? (
                  <button
                    type="button"
                    className="ProductVariantSelectOpen"
                    onClick={() => dispatch(setCurrentProductForVariants(item))}
                  >
                    <span>{selectedVariants[prop]}</span>
                    <span>optar</span>
                  </button>
                ) : keys.map(item => (
                  <button
                    key={item}
                    type="button"
                    className={`ProductVariantValue${selectedVariants[prop]!==item ? '' : ' current'}`}
                    onClick={() => setVari({...selectedVariants, [prop]: item })}
                  >
                    {item}
                  </button>
                ))}
              </div>
            )
          })}
        </div>
        <div className="ProductOptions">
          {showOptions && (
            <div className="ProductOptions">
              {selectedOptions.length > 0 && selectedOptions.map(s => (
                <span className="ProductOption" key={s.option.ID}>{s.option.Title} &times; {s.quantity}</span>
              ))}
              <button
                type="button"
                className="ProductOptionsSelectOpen"
                onClick={() => dispatch(setCurrentProductForOptions({ product: item, variants: selectedVariants }))}
              >
                Opções
              </button>
            </div>
          )}
        </div>
        <div className="ProductPrice">{price()} {currency}</div>
        <div className="ProductButtons">
          <div className="ProductQuantity">
            <button disabled={quan === 1} type="button" className="ProductQuantitySub" onClick={minus} title="Diminuir contagem">
              -
            </button>
            <span className="ProductQuantityNumber">{quan}</span>
            <button type="button" className="ProductQuantityAdd" onClick={plus} title="Aumentar contagem">
              +
            </button>
          </div>
          <button type="button" className="ProductAddToCart" onClick={toCart} title="Adicionar ao carrinho">
            <div className="icon icon-cart-white"></div>
          </button>
        </div>
      </div>
    </div>
  );
};

const ProductsList = ({ items }) => {
  return items.length > 0 ? (
    <div className="ProductsList">
      {items.map((item, i) => (
        <Product key={item.ID} item={item} index={i} />
      ))}
    </div>
  ) : null;
};

const ProductVariants = () => {
  const { variants, current } = useSelector(state => state.variants);
  
  const productId = current ? current.ID || 0 : 0;

  const selected = variants[productId] || [];

  const available = current && current.Meta && current.Meta['Variants']
    ? JSON.parse(current.Meta['Variants']) || {} : {};

  const dispatch = useDispatch();

  const setVari = (selected) => {
    dispatch(setVariants({ productId, selected }));
  };

  const close = () => dispatch(setCurrentProductForVariants(null));

  return productId !== 0 && (
    <div className="ProductVariantsSelect-wrap" onClick={close}>
      <div className="ProductVariantsSelect" onClick={e => e.stopPropagation()}>
        <div className="ProductOptionsSelectTitle">
          <span>Escolha variantes</span>
          <button
            type="button"
            className="ProductOptionsSelectClose"
            onClick={close}
          >
            ×
          </button>
        </div>
        <div className="ProductVariantsList">
          {Object.keys(available).map(prop =>  (
            <div className="ProductVariantsListItem" key={prop}>
              <span className="ProductVariantsListItemTitle">{prop}: </span>
              {Object.keys(available[prop]).map(item => (
                <button
                  key={item}
                  type="button"
                  className={`ProductVariantsListSubitem${selected[prop]!==item ? '' : ' current'}`}
                  onClick={() => setVari({...selected, [prop]: item })}
                >
                  {item}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const getOptionPrice = (option, variants) => {
  let optionPrice = Number(option.Meta['Price']) || 0;
  const optionPrices = option.Meta['Variants'] ? JSON.parse(option.Meta['Variants']) || {} : {};
  Object.keys(optionPrices).forEach(v => {
    optionPrice = Number(optionPrices[v][variants[v]]) || 0;
  });
  return optionPrice;
};

const ProductOptions = () => {
  const {
    fetching,
    all: allOptions,
    selected: options,
    currentProduct: current,
    currentProductVariants: variants
  } = useSelector(state => state.options);

  const productId = current ? current.ID || 0 : 0;

  const avbailable = current && current.Meta && current.Meta['Options']
    ? JSON.parse(current.Meta['Options']) || [] : [];

  const selected = options[productId] || [];

  const dispatch = useDispatch();

  const addOption = (option, add = true) => {
    const same = selected.find(s => s.option.ID === option.ID);
    let newValue = null;
    if (!same) {
      const newOption = { option, quantity: 1 };
      newValue = [...selected, newOption];
    } else {
      newValue = [...selected];
      const newQuantity = same.quantity + (add ? 1 : -1);
      const del = newValue.indexOf(same);
      if (del >= 0) {
        if (newQuantity > 0)
          newValue.splice(del, 1, { option, quantity: newQuantity });
        else newValue.splice(del, 1);
      }
    }
    dispatch(setSelectedOptions({ productId, selected: newValue }));
  }

  const close = () => dispatch(setCurrentProductForOptions({}));
  
  return productId !== 0 && avbailable.length > 0 && (
      <div className="ProductOptionsSelect-wrap" onClick={close}>
        <div className="ProductOptionsSelect" onClick={e => e.stopPropagation()}>
          <div className="ProductOptionsSelectTitle">
            <span>Adicionar opções</span>
            <button
              type="button"
              className="ProductOptionsSelectClose"
              onClick={close}
            >
              ×
            </button>
          </div>
          <div className="ProductOptionsList">
            {fetching && allOptions.length === 0 ? <Fetching /> : (
              allOptions.filter(o => avbailable.find(a => a === o.ID) !== undefined)
                .sort((a, b) => a.Content > b.Content ? 1 : (a.Content === b.Content ? 0 : -1))
                .map(option => (
                  <button
                    type="button"
                    key={option.ID}
                    title={option.Content}
                    className="ProductOptionsListItem"
                    onClick={() => addOption(option)}
                  >
                    <span>{option.Title}</span>
                    <span>{getOptionPrice(option, variants)} {currency}</span>
                  </button>
                )
              ))}
          </div>
          <div className="ProductOptionsSelected">
            <div className="ProductOptionsSelectedTitle">Opções adicionadas</div>
            <div className="ProductOptionsSelectedList">
              {selected.length > 0 ? selected.map(s => (
                <div key={s.option.ID} className="ProductOptionsSelectedItem">
                  <button type="button" className="ProductOptionsDecrease" onClick={() => addOption(s.option, false)}>
                    {s.quantity > 1 ? '-' : '×'}
                  </button>
                  <div className="ProductOptionsItemTitle">{s.option.Title} × {s.quantity}</div>
                  <button type="button" className="ProductOptionsIncrease" onClick={() => addOption(s.option)}>
                    +
                  </button>
                </div>
              )) : (
                <div className="ProductOptionsSelectedEmpty">nenhum</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
};

const Products = () => {
  const { categories: cats, fetching } = useSelector(state => state.categories);

  const { items: products, fetching: productsFetching } = useSelector(state => state.products);

  const { id } = useParams();

  const category = id ? Number(id) : null;

  const filtered = category ? products.filter(item => item['ParentID'] === category) : [...products];

  const sorted = filtered
    .sort((a, b) => a.Title > b.Title ? 1 : (a.Title === b.Title ? 0 : -1))
    .sort((a, b) => a.ParentID - b.ParentID);

  return (
    <div>
      <div className="ProductCategories-wrap">
        <Container>
          <div className="ProductCategories" role="menubar">
            <Link
              key={0}
              to={'/menu'}
              className={`ProductCategory${!category?' current':''} appear-seq`}
              role="menuitem"
            >
              Todas
            </Link>
            {cats.map((item, i) => (
              <Link
                key={item.ID}
                to={`/menu/${item.ID}`}
                role="menuitem"
                className={`ProductCategory${category!==item.ID?'':' current'} appear-seq`}
                style={{ '--animation-order': i+1 }}
              >
                {item.Title}
              </Link>
            ))}
          </div>
        </Container>
      </div>
      <div className="Products">
        <Container>
          <div className="ProductsList-wrap">
            {productsFetching || fetching ?
              <Fetching /> :
              <ProductsList items={sorted} />
            }
          </div>
        </Container>
      </div>
      <ProductVariants />
      <ProductOptions />
    </div>
  );
};

export default Products;
