import { Link } from 'react-router-dom';

import { developerEmail, developerName } from '../../../constants';

import Container from '../../elements/Container/Container';
import Section from '../../elements/Section/Section';

import './Footer.css';

function Footer({ ...rest }) {
  return (
    <footer className="Footer">
      <Container className="space-two" {...rest}>
        <div className="tiles between">
          <div className="tile">
            <Section name="Footer - Column 1" />
          </div>
          <div className="tile">
            <Section name="Footer - Column 2" />
          </div>
          <div className="tile">
            <Section name="Footer - Column 3" />
          </div>
          <div className="tile">
            <div>
              <p><Link to="/info/52">Contatos</Link></p>
              <p><Link to="/info/21">Localização</Link></p>
              <p><Link to="/info/167">Envio e pagamento</Link></p>
            </div>
          </div>
        </div>
      </Container>
      <div hidden>Developer <a href={`mailto:${developerEmail}`}>{developerName}</a></div>
    </footer>
  );
}

export default Footer;
