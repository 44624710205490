import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { currency } from '../../../constants';
import { shuffleArray } from '../../../helper';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Section from '../../elements/Section/Section';
import Image, { imageSrc } from '../../elements/Image/Image';

import Promos from '../Promos/Promos';

import './Home.css';

const ProductsMenuItem = ({ item, index }) => {
  return (
    <div key={item.ID} className="tile appear-seq" style={{ '--animation-order': index }}>
      <Link className="ProductsMenu-item" to={`/menu/${item.ID}`}>
        <span className="ProductsMenu-item-image-shadow" style={{ backgroundImage: `url(${imageSrc(item.Image)})` }} /> 
        <Image src={item.Image} className="ProductsMenu-item-image" />
        <span className="ProductsMenu-item-title">{item.Title}</span>
      </Link>
    </div>
  );
}

const ProductsMenu = () => {
  const { categories: cats, fetching } = useSelector(state => state.categories);

  return (
    <div className="ProductsMenu">
      <div className="HomeSectionTitle">Categorias</div>
      {!cats || cats.length === 0
        ? (fetching ? <Fetching /> : '')
        : cats.map((item, i) => <ProductsMenuItem key={item.ID} item={item} index={i} />)}
    </div>
  );
};

function About() {
  return (
    <Container>
      <div className="space-one">
        <Section name="Home - Main Text" skeleton={<Fetching />} />
      </div>
    </Container>
  );
}

function PopularItem({ item, index }) {
  const price = item.Meta && item.Meta['Price'] ? item.Meta['Price'] : null;
  return (
    <div className="tile appear-seq" style={{ '--animation-order': index }}>
      <div className="Bun Bun-small">
        <div className="Bun-image">
          <Image src={item.Image} loading="lazy" />
        </div>
        <Link to={`/menu/${item.ParentID}`} className="Bun-text Bun-text-top bg-gradient-top">{item.Title}</Link>
        {price && (
          <div className="Bun-text Bun-text-bottom Bun-text-right">{price} {currency}</div>
        )}
      </div>
    </div>
  );
}

function Popular() {
  const { items, fetching } = useSelector(state => state.products);

  // TODO: splice -> filter by unique category
  const popular = shuffleArray([...items]).splice(0, 5);

  return (
    <div className="space-two">
      <div className="tiles evenly">
        {fetching && popular.length === 0 ? <Fetching /> : (
          popular.map((item, i) => (
            <PopularItem key={item.ID} item={item} index={i} />
          ))
        )}
      </div>
    </div>
  );
}

function Home() {
  const { settings } = useSelector(state => state.settings);

  const backgroundPath = settings['Background Image'] || '';

  const backgroundFullPath = imageSrc(backgroundPath);
  const style = { backgroundImage: `url(${backgroundFullPath}), linear-gradient(15deg, #BF2B49, #529D64)` };

  return (
    <div className="Home">
      <section className="About-section fade-in">
        <About />
      </section>
      <section className="ProductsMenu-section fade-in" style={style}>
        <Container>
          <div className="flex center space-two">
            <ProductsMenu />
          </div> 
        </Container>
      </section>
      <section className="Promo-section">
        {/* <div className="HomeSectionTitle">Ofertas</div> */}
        <Promos />
      </section>
      <section className="Popular-section bg-lightgray fade-in">
        <div className="HomeSectionTitle">Pode lhe interessar</div>
        <Container>
          <Popular />
        </Container>
      </section>
      <section className="Social-section space-two">
        <Container>
          <Section name="Home - Bottom" skeleton={<Fetching />} />
        </Container>
      </section>
    </div>
  );
}

export default Home;
