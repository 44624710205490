import { createSlice } from '@reduxjs/toolkit';

const fromLocalStorage = localStorage.getItem('settings');
const parsed = fromLocalStorage ? JSON.parse(fromLocalStorage) : {};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    fetching: false,
    settings: parsed,
  },
  reducers: {
    setItems: (state, action) => {
      const items = action.payload;
      if (items) {
        state.settings = items;
        const json = JSON.stringify(items);
        localStorage.setItem('settings', json);
      }
    },
    setFetching: (state, action) => {
      state.fetching = action.payload || false;
    },
  },
});

export const { setItems: setSettings, setFetching: setSettingsFetching } = settingsSlice.actions;

export default settingsSlice.reducer;
