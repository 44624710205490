import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getRecordsWithMeta } from '../../../requests';

import { add as addMessage } from '../../../state/notifySlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Markdown from '../../elements/Markdown/Markdown';

import './Reviews.css';

const STAR = '★';

function ReviewRating ({ value }) {
  const count = Number(value) || 0;
  return count && (
    <span class="ReviewRating" title={`${count} stars`}>
      {STAR.repeat(count)}
    </span>
  );
}

function ReviewsListItem({ item, index }) {
  return (
    <div className="Review Bun space-one appear-seq" style={{ '--animation-order': index }}>
      <div className="ReviewTitle">
        <span>{item.Title}</span>
        <ReviewRating value={item.Meta['Rating'] || ''} />
      </div>
      <Markdown className="ReviewContent">{item.Content || ''}</Markdown>
      {item.Meta['Author'] && (
        <div className="ReviewAuthor">{item.Meta['Author']}</div>
      )}
    </div>
  );
}

function ReviewsList({ items }) {
  return items.length > 0 ? (
    <div className="ReviewsList">
      {items.map((item, i) => <ReviewsListItem key={item.ID} item={item} index={i} />)}
    </div>
  ) : null;
}

function Reviews() {
  const [fetching, setFetching] = useState(false);
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let disposed = false;
    setFetching(true);
    getRecordsWithMeta({ type: 'review' })
      .then(({ data }) => {
        if (!disposed) {
          const records = data || [];
          const sorted = records.sort((a, b) => a.Created < b.Created);
          setItems(sorted);
        }
      })
      .catch(error => {
        console.error(error);
        if (!disposed) dispatch(addMessage({
          type: 'error',
          text: 'Erro ao carregar "reviews"'
        }));
      })
      .finally(() => { if (!disposed) setFetching(false); });
    return () => disposed = true;
  }, [dispatch]);

  return (
  <div className="Reviews space-two">
    <Container>
      {fetching ? <Fetching /> : <ReviewsList items={items} />}
    </Container>
  </div>
  );

}

export default Reviews;
